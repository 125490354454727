/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


/** global: Hammer */
(function ($, window) {
  "use strict";

  function stringRepeat(s, precision) {
    // String repeat polyfill
    if (!String.prototype.repeat) {
      precision = precision || 1;
      return new Array(precision + 1).join(s);
    }
    return s.repeat(precision);
  }

  var pluginName = 'imagesCompare',
    defaults = {
      initVisibleRatio: 0.5,
      interactionMode: "drag", // "drag", "mousemove", "click"
      animationDuration: 400, // default animation duration in ms
      animationEasing: "swing",
      addSeparator: true, // add a html element on the separation
      addDragHandle: true, // add a html drag handle element on the separation
      precision: 4
    };

  // Our object, using revealing module pattern
  function ImagesCompare(element, options) {
    element = $(element);
    options = $.extend({}, defaults, options);
    options.roundFactor = parseInt('1' + stringRepeat('0', options.precision));

    this._name = pluginName;

    var frontElement, backElement, separator, dragHandle, lastRatio = 1,
      size = {
        width: 0,
        height: 0,
        maxWidth: 0,
        maxHeight: 0
      },
      events = {
        initialised: "imagesCompare:initialised",
        changed: "imagesCompare:changed",
        resized: "imagesCompare:resized"
      };

    function onImagesLoaded() {
      var images = element.find('img'),
        totalImagesCount = images.length,
        elementsLoaded = 0;

      function onImageLoaded() {
        if (elementsLoaded >= totalImagesCount) {
          init();
        }
      }

      images.each(function () {
        // Image already loaded (cached)
        if ($(this)[0].complete) {
          totalImagesCount--;
          onImageLoaded();
        } else {
          // Image loading / error
          $(this).on('load', function () {
            elementsLoaded++;
            onImageLoaded();
          });
          $(this).on('error', function () {
            elementsLoaded++;
            onImageLoaded();
          });
        }
      });
    }

    onImagesLoaded();

    function init() {
      updateDom();
      patchSize();
      initInteractions();

      $(frontElement).attr('ratio', options.initVisibleRatio);
      setVisibleRatio(options.initVisibleRatio);

      // Let the world know we have done the init
      element.trigger({
        type: events.initialised
      });
    }

    function addResize() {
      $(window).on('resize', function (event) {
        frontElement.css('clip', '');
        patchSize();
        setVisibleRatio(lastRatio);

        // Let the world know we have done some resize updates
        element.trigger({
          type: events.resized,
          originalEvent: event
        });
      });
    }

    function initInteractions() {
      options.interactionMode = options.interactionMode.toLowerCase();

      if (options.interactionMode != "drag" && options.interactionMode != "mousemove" && options.interactionMode != "click") {
        console.warn('No valid interactionMode found, valid values are "drag", "mousemove", "click"');
      }

      switch (options.interactionMode) {
        case "drag":
          initDrag();
          break;
        case "mousemove":
          initMouseMove();
          break;
        case "click":
          initClick();
          break;
        default:
          initDrag();
      }
    }

    function initDrag() {
      if (typeof Hammer == 'undefined') {
        console.error('Please include the hammerjs library for drag support');
      }
      addDrag();
      addResize();
    }

    function initMouseMove() {
      addMouseMove();
      addResize();
    }

    function initClick() {
      addClick();
      addResize();
    }

    function addClick() {
      element.on('click', function (event) {
        var ratio = getElementRatio(event.pageX);
        setVisibleRatio(ratio);
      });
    }

    function addMouseMove() {
      var lastMove = 0;
      var eventThrottle = 1;
      element.on('mousemove', function (event) {
        event.preventDefault();
        var now = Date.now();
        if (now > lastMove + eventThrottle) {
          lastMove = now;
          var ratio = getElementRatio(event.pageX);
          setVisibleRatio(ratio);
        }
      });

      element.on('mouseout', function (event) {
        var ratio = getElementRatio(event.pageX);
        setVisibleRatio(ratio);
      });
    }

    function addDrag() {
      var hammertime = new Hammer(element[0]);
      hammertime.get('pan').set({
        direction: Hammer.DIRECTION_HORIZONTAL
      });
      hammertime.on('pan', function (event) {
        var ratio = getElementRatio(event.srcEvent.pageX);
        setVisibleRatio(ratio);
      });
    }

    function updateDom() {
      element.addClass('images-compare-container');
      element.css('display', 'inline-block');

      frontElement = element.find('> *:nth-child(1)');
      backElement = element.find('> *:nth-child(2)');

      frontElement.addClass("images-compare-before");
      frontElement.css('display', 'block');
      backElement.addClass("images-compare-after");
      backElement.css('display', 'block');

      if (options.addDragHandle) {
        buildDragHandle();
      }

      if (options.addSeparator) {
        buildSeparator();
      }
    }

    function buildSeparator() {
      element.prepend("<div class='images-compare-separator'></div>");
      separator = element.find(".images-compare-separator");

    }

    function buildDragHandle() {
      element.prepend("<div class='images-compare-handle'></div>");
      dragHandle = element.find(".images-compare-handle");
      dragHandle.append("<span class='images-compare-left-arrow'></span>");
      dragHandle.append("<span class='images-compare-right-arrow'></span>");
    }

    function patchSize() {
      var imgRef = backElement.find('img').first();
      setSize(imgRef.width(), imgRef.height(), imgRef.naturalWidth(), imgRef.naturalHeight());
      element.css('max-width', size.maxWidth + 'px');
      element.css('max-height', size.maxHeight + 'px');
      frontElement.width(size.width);
      frontElement.height(size.height);
    }

    /**
     *
     * @param x
     * @return float
     */
    function getElementRatio(x) {
      return roundRatio((x - element.offset().left) / frontElement.width());
    }

    /**
     *
     * @param ratio
     * @return float
     */
    function roundRatio(ratio) {
      ratio = Math.round((ratio * options.roundFactor)) / options.roundFactor;
      if (ratio > 1) {
        ratio = 1;
      }

      if (ratio < 0) {
        ratio = 0;
      }

      return ratio;

    }

    /**
     * Animation request
     *
     * @param startValue float
     * @param endValue float
     * @param duration value in ms
     * @param easing linear or swing
     */
    function launchAnimation(startValue, endValue, duration, easing) {
      $(frontElement).attr('ratio', startValue).animate({
        ratio: startValue
      }, {
        duration: 0
      });

      $(frontElement).stop().attr('ratio', startValue).animate({
        ratio: endValue
      }, {
        duration: duration,
        easing: easing,
        step: function (now) {
          var width = getRatioValue(now);
          lastRatio = now;
          frontElement.attr('ratio', now).css('clip', 'rect(0, ' + width + 'px, ' + size.height + 'px, 0)');

          if (options.addSeparator) {
            separator.css('left', width + 'px');
          }

          if (options.addDragHandle) {
            dragHandle.css('left', width + 'px');
          }
        },
        done: function (animation, jumpedToEnd) {
          var ratio = $(frontElement).attr('ratio');
          // Let the world know something has changed
          element.trigger({
            type: events.changed,
            ratio: ratio,
            value: getRatioValue(ratio),
            animate: true,
            animation: animation,
            jumpedToEnd: jumpedToEnd
          });
        }
      });
    }

    /**
     * Get value to reach, based on a ratio
     *
     * @param ratio float
     * @return {number}
     */
    function getRatioValue(ratio) {
      ratio = Math.round((ratio * options.roundFactor)) / options.roundFactor;
      return Math.round(frontElement.width() * ratio);
    }

    /**
     * Change visible ratio
     *
     * @param ratio float
     * @param animate boolean Do we want an animation ?
     * @param duration in ms
     * @param easing 'swing', 'linear'
     */
    function setVisibleRatio(ratio, animate, duration, easing) {
      if (typeof animate == 'undefined') {
        animate = false;
      }

      var width = getRatioValue(ratio);

      if (animate) {
        var finalDuration = duration ? duration : options.animationDuration;
        var finalEasing = easing ? easing : options.animationEasing;

        launchAnimation(lastRatio, ratio, finalDuration, finalEasing);

        // Let the world know something has changed
        if (lastRatio != ratio) {
          element.trigger({
            type: events.changed,
            ratio: lastRatio,
            value: width,
            animate: animate
          });
        }

        return;

      } else {
        frontElement.stop().css('clip', 'rect(0, ' + width + 'px, ' + size.height + 'px, 0)');

        if (options.addSeparator) {
          $(separator).stop().css('left', width + 'px');
        }

        if (options.addDragHandle) {
          dragHandle.css('left', width + 'px');
        }
      }

      // Let the world know something has changed
      if (lastRatio != ratio) {
        element.trigger({
          type: events.changed,
          ratio: ratio,
          value: width,
          animate: animate
        });
      }

      lastRatio = ratio;
    }

    function setSize(width, height, maxWidth, maxHeight) {
      if (typeof width != 'undefined') {
        setWidth(width);
      }
      if (typeof height != 'undefined') {
        setHeight(height);
      }
      if (typeof maxWidth != 'undefined') {
        setMaxWidth(maxWidth);
      }
      if (typeof maxHeight != 'undefined') {
        setMaxHeight(maxHeight);
      }
      return size;
    }

    function setWidth(width) {
      size.width = width;
      return size;
    }

    function setMaxWidth(maxWidth) {
      size.maxWidth = maxWidth;
      return size;
    }

    function setHeight(height) {
      size.height = height;
      return size;
    }

    function setMaxHeight(maxHeight) {
      size.maxHeight = maxHeight;
      return size;
    }

    // public function declaration
    // returning element to preserve chaining
    return {
      "setValue": function (ratio, animate, duration, easing) {
        setVisibleRatio(ratio, animate, duration, easing);
        return element;
      },
      "getValue": function () {
        return lastRatio;
      },
      "on": function (eventName, callback) {
        element.on(eventName, callback);
        return element;
      },
      "off": function (eventName, callback) {
        element.off(eventName, callback);
        return element;
      },
      "events": function () {
        return events;
      }
    };
  }


  /**
   * Plugin declaration
   *
   * @param userOptions
   * @return {*}
   */
  $.fn.imagesCompare = function (userOptions) {
    var options = $.extend(defaults, userOptions);
    return this.each(function () {
      if (!$.data(this, pluginName)) {
        $.data(this, pluginName, new ImagesCompare(this, options));
      }
    });
  };

})(jQuery, window, document);

// http://www.jacklmoore.com/notes/naturalwidth-and-naturalheight-in-ie/
(function ($) {
  var props = ['Width', 'Height'],
    prop, propsLength;

  propsLength = props.length;

  for (var index = 0; index < propsLength; index++) {
    prop = props[index];
    /*jslint loopfunc: true */
    (function (natural, prop) {
      $.fn[natural] = (natural in document.createElement('img')) ?
        function () {
          return this[0][natural];
        } :
        function () {
          var
            node = this[0],
            img,
            value = 0;

          if (node.tagName.toLowerCase() === 'img') {
            img = document.createElement('img');
            img.src = node.src;
            value = img[prop];
          }
          return value;
        };
    }('natural' + prop, prop.toLowerCase()));
    /*jslint loopfunc: false */
  }
}(jQuery));



(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

          function heroBannerResize(){
              var window_width = $( window ).width();
              var hero_width = $("#hero-banner div.home-carousel-wrapper").width();
              if( window_width < hero_width ){
                  var left = (window_width - hero_width) / 2;
                  $("#hero-banner div.home-carousel-wrapper").css("left", left);
              }else{
                  $("#hero-banner div.home-carousel-wrapper").css("left", 0);
              }

          }

          $( window ).resize(function() {
              heroBannerResize();
          });
          heroBannerResize();

          $('#owl-carousel-gallery').owlCarousel({
              items: 1,
              loop: true,
              mouseDrag: false,
              touchDrag: false,
              dots: true,
              autoplay: true,
              autoplayHoverPause: true,
              autoplaySpeed:500,
              animateOut: 'fadeOut'
          });

          $("button.navbar-toggler").click(function(){
             $(this).toggleClass("is-active");
          });

          $(window).scroll(function() {
              if ($(this).scrollTop() >= 50) {
                  $('#btn-back-to-top').fadeIn(200);
              } else {
                  $('#btn-back-to-top').fadeOut(200);
              }
          });
          $('#btn-back-to-top').click(function() {
              $('body,html').animate({
                  scrollTop : 0
              }, 500);
          });
          if($('#statusCompare')){
            $('#statusCompare').imagesCompare();  
          }
          $('[id*="similCompaire"]').imagesCompare();          
              
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        // Input File
          var input = $( '#fichier' );
          var label  = $('.contact-form .file label');            
            console.log(label);
          input.on( 'change', function( e )
          {                     
            var fileName = e.target.value.split( '\\' ).pop();                  
              label.html(fileName);
          });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

          $('#owl-carousel-discovery').owlCarousel({
              items: 1,
              loop: true,
              mouseDrag: false,
              touchDrag: false,
              dots: true,
              autoplay: true,
              autoplayHoverPause: false,
              autoplaySpeed:500,
              animateOut: 'fadeOut'
          });

          $('#owl-carousel-testimonials').owlCarousel({
              loop:true,
              margin:10,
              responsiveClass:true,
              navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
              responsive:{
                  0:{
                      items:1,
                      dots: true,
                      nav: false
                  },
                  576:{
                      items:2,
                      dots: false,
                      nav: true
                  },
                  992:{
                      items:3,
                      dots: false,
                      nav: true
                  }
              }
          });

          $('#owl-carousel-advices').owlCarousel({
              loop:false,
              pullDrag: false,
              margin:10,
              dots: false,
              nav: false,
              responsiveClass:true,
              responsive:{
                  0:{
                      items:1
                  },
                  576:{
                      items:2
                  },
                  768:{
                      mouseDrag: false,
                      touchDrag: false,
                      items:3
                  }
              }
          });

          $(".service-item").click(function(){
              var link = $(this).find("[href]").attr("href");
              window.location = link;
          });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'traitements': {
      init: function() {
        var carousel;
        $('#myImageCompare').imagesCompare();
        
        if ( $(window).width() < 767 ) {
            setCarousel();
        }

        $( window ).resize(function() {
          if ( $(window).width() < 767 ) {
              setCarousel();
          }else{
            unsetCarousel();
          }
        });

        function setCarousel(){        
          if(carousel == null){                        
              carousel = $("#procedure-etapes");
              carousel.addClass('owl-carousel-gallery owl-theme');
              carousel.removeClass('row');
              carousel.owlCarousel({
                  items: 1,
                  loop: false,
                  mouseDrag: true,
                  touchDrag: true,
                  dots: true,
                  nav: false                          
              });
          }
        }
        function unsetCarousel(){        
              if(carousel != null){            
                  carousel.removeClass('owl-carousel-gallery owl-theme');
                  carousel.addClass('row');
                  carousel.trigger('destroy.owl.carousel');
                  carousel = null;
              }
          }
      }
    },
    'clinique': {
      init: function() {
        $("blockquote p:first-child").prepend("<span>« </span>");
        $("blockquote p:last-child").append("<span> »</span>");
      }
    },
    'le_blanchiment_dentaire': {
      init: function() {
        var carousel;
        $('#myImageCompare').imagesCompare();
        if ( $(window).width() < 767 ) {
            setCarousel();
        }

        $( window ).resize(function() {
          if ( $(window).width() < 767 ) {
              setCarousel();
          }else{
            unsetCarousel();
          }
        });

        function setCarousel(){        
          if(carousel == null){                        
              carousel = $("#procedure-etapes");
              carousel.addClass('owl-carousel-gallery owl-theme');
              carousel.removeClass('row');
              carousel.owlCarousel({
                  items: 1,
                  loop: false,
                  mouseDrag: true,
                  touchDrag: true,
                  dots: true,
                  nav: false                          
              });
          }
        }
        function unsetCarousel(){        
              if(carousel != null){            
                  carousel.removeClass('owl-carousel-gallery owl-theme');
                  carousel.addClass('row');
                  carousel.trigger('destroy.owl.carousel');
                  carousel = null;
              }
          }
      }
    },
    'couronne_dentaire': {
      init: function() {
        var carousel;
        $('#myImageCompare').imagesCompare();
        if ( $(window).width() < 767 ) {
            setCarousel();
        }

        $( window ).resize(function() {
          if ( $(window).width() < 767 ) {
              setCarousel();
          }else{
            unsetCarousel();
          }
        });

        function setCarousel(){        
          if(carousel == null){                        
              carousel = $("#procedure-etapes");
              carousel.addClass('owl-carousel-gallery owl-theme');
              carousel.removeClass('row');
              carousel.owlCarousel({
                  items: 1,
                  loop: false,
                  mouseDrag: true,
                  touchDrag: true,
                  dots: true,
                  nav: false                          
              });
          }
        }
        function unsetCarousel(){        
              if(carousel != null){            
                  carousel.removeClass('owl-carousel-gallery owl-theme');
                  carousel.addClass('row');
                  carousel.trigger('destroy.owl.carousel');
                  carousel = null;
              }
          }
      }
    },
    'bridge_dentaire': {
      init: function() {
        var carousel;
        $('#myImageCompare').imagesCompare();
        if ( $(window).width() < 767 ) {
            setCarousel();
        }

        $( window ).resize(function() {
          if ( $(window).width() < 767 ) {
              setCarousel();
          }else{
            unsetCarousel();
          }
        });

        function setCarousel(){        
          if(carousel == null){                        
              carousel = $("#procedure-etapes");
              carousel.addClass('owl-carousel-gallery owl-theme');
              carousel.removeClass('row');
              carousel.owlCarousel({
                  items: 1,
                  loop: false,
                  mouseDrag: true,
                  touchDrag: true,
                  dots: true,
                  nav: false                          
              });
          }
        }
        function unsetCarousel(){        
              if(carousel != null){            
                  carousel.removeClass('owl-carousel-gallery owl-theme');
                  carousel.addClass('row');
                  carousel.trigger('destroy.owl.carousel');
                  carousel = null;
              }
          }
      }
    },
    'cas_de_realisation': {
      init: function() {        
        $('#myImageCompare').imagesCompare();        
      }
    },
    'contact': {
      finalize: function() {
        var map = null;
        function new_map( $el ) {
          var $markers = $el.find('.marker');      

          var args = {
            zoom    : 16,
            center    : new google.maps.LatLng(0, 0),
            mapTypeId : google.maps.MapTypeId.ROADMAP
          };
          
          var map = new google.maps.Map( $el[0], args);
          
          map.markers = [];        
          $markers.each(function(){          
              add_marker( $(this), map );          
          });
          
          center_map( map );      
          return map;        
        }
      
        function add_marker( $marker, map ) {
          var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

          var marker = new google.maps.Marker({
            position  : latlng,
            map     : map,
            icon: "/wp-content/uploads/cedro-poi.png"
          });
          map.markers.push( marker );                
        }     

        function center_map( map ) {
          var bounds = new google.maps.LatLngBounds();
          // loop through all markers and create bounds
          $.each( map.markers, function( i, marker ){
            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
            bounds.extend( latlng );
          });
          map.setCenter( bounds.getCenter() );
          map.setZoom( 16 );          
        }  
        setTimeout(function(){
          elm = $(".acf-map");
          map = new_map(elm);
        }, 500);
        
      }
    }       
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
